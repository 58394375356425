import { Network } from "@capacitor/network";
import { hackAlertChange } from "@src/app/utils/alertChangeHelper";
import { useState } from "react";

export type CustomAlert = {
  header?: string;
  message: string;
  buttons: Array<{
    text: string;
    handler: () => void;
    cssClass?: string;
  }>;
};

/**
 * @deprecated
 * These unusual `useDeprecatedAlertsForShiftDetails` code implementations are an attempt to display
 * a message to the user, along with call-to-action responses.
 * This is non-idiomatic for React.
 * Instead, use a standard React component local to the code that
 * handles the notification, along with local action handlers.
 * This is not a standard React pattern and also creates convoluted
 * code patterns and code bloat.
 * This code pattern results in a non-trivial wiring of code in
 * handler functions.
 * Instead of this, the code should be encapsulated in separate
 * components, one for each alert dialog.
 */
export const useDeprecatedAlertsForShiftDetails = () => {
  const [alert, setAlert] = useState<CustomAlert | null>(null);

  const dismissAlert = () => setAlert(null);

  const alertSkipLocationConfirmation = (opts: {
    goBackBtnHandler: () => void;
    acceptNonInstantPayBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Non-InstantPay",
      message: [
        "If we cannot confirm your location, your shift will be converted to Non-InstantPay.",
        "That means you will receive payment only when the shift is verified.",
      ].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Accept Non-InstantPay",
          handler: opts.acceptNonInstantPayBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertCancelEditedTime = (opts: {
    goBackBtnHandler: () => void;
    cancelEditBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Cancel Edit",
      message: ["If we cannot confirm your location, you cannot edit your times."].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Do Not Edit Times",
          handler: opts.cancelEditBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertNoNetworkConnection = (opts: { isEdit?: boolean }) => {
    let firstLineOfMessage =
      "Your shift is done, but you don't have service! Connect to WiFi or cellular data to get paid as soon as possible.";
    let secondLineOfMessage =
      "If you can't get service now, you have up to 24 hours to connect. Just open the app to try again!";
    if (opts.isEdit) {
      firstLineOfMessage = "Connect to WiFi or cellular data to enable location access.";
      secondLineOfMessage = "Otherwise, you cannot edit times.";
    }
    setAlert({
      header: "No Network Connection",
      message: [firstLineOfMessage, secondLineOfMessage].join("\n\n"),
      buttons: [
        {
          text: "Later",
          handler: () => setAlert(null),
        },
        {
          text: "Try again",
          handler: async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const status = await Network.getStatus();
            if (!status.connected) {
              alertNoNetworkConnection({ isEdit: opts.isEdit ?? false });
            }
          },
        },
      ],
    });
  };

  const alertLocationAccess = (opts: {
    openLocationSettingsFn: () => void;
    skipLocationBtnHandler: () => void;
    isEdit?: boolean;
  }) => {
    setAlert({
      header: "Location Access",
      message:
        "We need to verify that you're at the workplace. Please grant us location access in your device settings.",
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip Location Confirmation",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertLocationAccess(opts);
                  },
                  cancelEditBtnHandler: opts.skipLocationBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipLocationConfirmation({
                  goBackBtnHandler: () => {
                    alertLocationAccess(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipLocationBtnHandler,
                });
              }),
        },
        {
          text: "Grant Access",
          handler: opts.openLocationSettingsFn,
        },
      ],
    });
  };

  const alertBrowserLocationAccess = () => {
    setAlert({
      header: "Open Browser Settings",
      message: "Please open your browser settings and re-enable location access to this site",
      buttons: [
        {
          text: "Got it",
          handler: dismissAlert,
        },
      ],
    });
  };

  const alertReturnToTheFacility = (opts: {
    facilityName?: string;
    isEdit?: boolean;
    stageText?: string;
    tryAgainBtnHandler: () => void;
    skipLocationBtnHandler: () => void;
    isSecondAttempt?: boolean;
  }) => {
    setAlert({
      header: "You're too far away",
      message: opts.isSecondAttempt
        ? [
            "This facility's perimeter has changed. ",
            "We recently redrew facility perimeters and so it's likely that you are too far away from the facility. Please enter the facility and try again.",
          ].join("\n\n")
        : [
            `You are currently outside the perimeter of ${opts?.facilityName ?? "the workplace"}. `,
            "You must clock in from inside the building — being outside or in the parking lot will not work.",
          ].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip Location Confirmation",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertReturnToTheFacility(opts);
                  },
                  cancelEditBtnHandler: opts.skipLocationBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipLocationConfirmation({
                  goBackBtnHandler: () => {
                    alertReturnToTheFacility(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipLocationBtnHandler,
                });
              }),
        },
        {
          text: "Try again - I'm inside the building",
          handler: hackAlertChange(opts.tryAgainBtnHandler),
        },
      ],
    });
  };

  const alertUploadTimesheet = (opts: {
    formattedRemainingAmount: string;
    is100InstantPayEnabled?: boolean;
    uploadTimesheetFn: () => void;
  }) => {
    setAlert({
      header: "Upload Shift",
      message: opts.is100InstantPayEnabled
        ? "Please upload your timesheet so that you can be paid."
        : `Nice work! Your shift is complete, and you'll receive the rest of your payment $${opts.formattedRemainingAmount} when the shift is verified.`,
      buttons: [
        {
          text: "Upload Later",
          handler: dismissAlert,
        },
        {
          text: "Upload Now",
          handler: opts.uploadTimesheetFn,
        },
      ],
    });
  };

  const alertMandatoryBreakEarlyEndConfirmation = ({
    timeRemainingMinutes,
    proceedHandler,
    cancelHandler,
  }: {
    timeRemainingMinutes: number;
    proceedHandler: () => void;
    cancelHandler: () => void;
  }) => {
    setAlert({
      message: `This workplace requires a 30-minute break. You have ${timeRemainingMinutes} minute${
        timeRemainingMinutes !== 1 ? "s" : ""
      } remaining on your break. Are you sure you want to end it now?`,
      buttons: [
        {
          text: "Cancel",
          handler: cancelHandler,
        },
        {
          text: "Yes, end the break now",
          handler: proceedHandler,
        },
      ],
    });
  };

  const alertEarlyBreakConfirmation = ({
    proceedHandler,
    cancelHandler,
  }: {
    proceedHandler: () => void;
    cancelHandler: () => void;
  }) => {
    setAlert({
      message: `Your break will start right away, are you sure?`,
      buttons: [
        {
          text: "Cancel",
          handler: cancelHandler,
        },
        {
          text: "Yes, start the break now",
          handler: proceedHandler,
        },
      ],
    });
  };

  const alertEarlyClockOutConfirmation = ({
    proceedHandler,
    cancelHandler,
  }: {
    proceedHandler: () => void;
    cancelHandler: () => void;
  }) => {
    setAlert({
      message: `Are you sure you want to clock out?`,
      buttons: [
        {
          text: "Cancel",
          handler: cancelHandler,
        },
        {
          text: "Yes, clock me out",
          handler: proceedHandler,
        },
      ],
    });
  };

  return {
    alert,
    dismissAlert,
    alertNoNetworkConnection,
    alertLocationAccess,
    alertBrowserLocationAccess,
    alertReturnToTheFacility,
    alertUploadTimesheet,
    alertCancelEditedTime,
    alertMandatoryBreakEarlyEndConfirmation,
    alertEarlyBreakConfirmation,
    alertEarlyClockOutConfirmation,
  };
};

export type ShiftDetailsAlerts = ReturnType<typeof useDeprecatedAlertsForShiftDetails>;
